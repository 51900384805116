/** @jsx jsx */
import { jsx } from '@emotion/core'
import rehypeReact from 'rehype-react'
import { graphql } from 'gatsby'

import Helmet from '../components/HelmetHelper'
import Container from '../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../components/content/Text'
import { Row, Cell } from '../components/Grid'
import { media, THEME_WHITE, THEME_PEACH } from '../constants'
import Slide, { SLIDE_LAYOUT_MOCK_FULL_SCREEN } from '../components/Slide'
import { provideAccentTheme } from '../context/AccentThemeContext'
import {
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_LARGE,
} from '../components/content/Layout'
import { createElement } from 'react'

export const pageQuery = graphql`
	query {
		markdownRemark(frontmatter: { id: { eq: "terms" } }) {
			htmlAst
			frontmatter {
				title
			}
		}
	}
`
const renderAst = new rehypeReact({
	createElement: createElement,
	// Fragment: LayoutTextSplit,
}).Compiler

const TermsPage = ({ data }) => {
	const { markdownRemark } = data
	const { htmlAst, frontmatter } = markdownRemark
	return (
		<div>
			<Helmet>
				<title>{frontmatter.title}</title>
				<meta
					name="description"
					content="Free Association is a design agency focused on digital products and brand systems. We’ve built longstanding partnerships with companies like Google, Toyota, eBay, PayPal, Waze, Equinox and a range of new ventures."
				/>
			</Helmet>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}
			>
				<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_LARGE}>
					<Container>
						<Row>
							<Cell
								cols={{
									[media.small]: 12,
									[media.mediumUp]: 10,
								}}
								offset={{
									[media.small]: 0,
									[media.mediumUp]: 1,
								}}
							>
								<Text>
									<h1 className="--headline">{frontmatter.title}</h1>
								</Text>
							</Cell>
						</Row>
					</Container>
					<Container>
						<Row>
							<Cell
								cols={{
									[media.small]: 12,
									[media.medium]: 10,
									[media.large]: 8,
								}}
								offset={{
									[media.small]: 0,
									[media.mediumUp]: 1,
								}}
							>
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									{renderAst(htmlAst)}
								</Text>
							</Cell>
						</Row>
					</Container>
				</LayoutVerticalCadence>
			</Slide>
		</div>
	)
}

export default provideAccentTheme(TermsPage, THEME_PEACH)
